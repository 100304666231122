/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Confirmation from "./Confirmation";
import { createConfirmation } from "react-confirm";
import { faFunction } from "@fortawesome/pro-solid-svg-icons";
import { toast } from "react-toastify";
import { AuthContext } from "../providers/AuthProvider";

export const rteMarkup = (messageRaw) => {
    let finalText = ''
    let message = ''

    
    if(typeof messageRaw === 'object'){
        message = messageRaw
    }else{
        message = JSON.parse(messageRaw)
    }

    
    message.blocks.forEach((block, key) => {
        
        let textContent = block.text;
        let textClone = block.text.replace(/\n/g, "<br />");

        block.entityRanges.forEach((range) => {
            
            let temp = textContent.substring(range.offset, (range.length + (range.offset)))
            
            if(message.entityMap[range.key].type === 'mention'){
                textClone = textClone.replace(temp, `<a href="#" class="link">${message.entityMap[range.key].data.mention.fullname}</a> `)
            }

            if(message.entityMap[range.key].type === 'LINK'){
                textClone = textClone.replace(temp, `<a href="${message.entityMap[range.key].data.url}" target="${message.entityMap[range.key].data.targetOption}" class="link">${temp}</a> `)
            }

            if(message.entityMap[range.key].type === '#mention'){
                textClone = textClone.replace(temp, `<a href="#" class="link">${message.entityMap[range.key].data.mention.title}</a>`)
            }

            if(message.entityMap[range.key].type === 'IMAGE'){
                textClone = textClone.replace(temp, `<img src="${message.entityMap[range.key].data.src}" />`)
            }
            
        })

        block.inlineStyleRanges.forEach((range) => {
                
            let temp = textContent.substring(range.offset, (range.length + (range.offset)))
            switch(range.style){
                case 'ITALIC':
                    textClone = textClone.replace(temp, `<i>${temp}</i>`)
                    break;
                case 'BOLD':
                    textClone = textClone.replace(temp, `<b>${temp}</b>`)
                    break;
                case 'UNDERLINE':
                    textClone = textClone.replace(temp, `<u>${temp}</u>`)
                    break;
            }
        })

        switch(block.type){
            case 'header-one':
                finalText += `<h1>${textClone}</h1>`
                break;
            case 'header-two':
                finalText += `<h2>${textClone}</h2>`
                break;
            case 'header-three':
                finalText += `<h3>${textClone}</h3>`
                break;
            case 'header-four':
                finalText += `<h4>${textClone}</h4>`
                break;
            case 'header-five':
                finalText += `<h5>${textClone}</h5>`
                break;
            case 'unordered-list-item':
                
                finalText += !message.blocks[key - 1]?.type === 'unordered-list-item' ? '<ul>' : ''
                finalText += `<li>${textClone}</li>`
                finalText += !message.blocks[key + 1]?.type === 'unordered-list-item' ? '</ul>' : ''
               
                break
            case 'ordered-list-item': 
                          
                finalText += !message.blocks[key - 1]?.type === 'ordered-list-item' ? '<ol>' : ''
                finalText += `<li>${textClone}</li>`
                finalText += !message.blocks[key + 1]?.type === 'ordered-list-item' ? '</ol>' : ''
               
                break
            default:
                finalText += `<p>${textClone}</p>`
        }
    })

    return finalText
}

export const formatDate = (data, format, prefix = false) => {
    const now = moment()
    const date = moment(data)

    if(!format) return date.format('DD.MM.YYYY')
    return date.format(format)
}
export const ago = (data) => {
	const now = moment()
	const date = moment(data)
  let diff = now.diff(date, 'minutes')

  if(diff >= 60){
	  diff = now.diff(date, 'hours')
    if(diff >= 24){
      diff = now.diff(date, 'days')
      if(diff >= 30){
        diff = now.diff(date, 'months')
        if(diff >= 12){
          diff = now.diff(date, 'years')
          return   `${diff} ${diff > 1 ? 'years' : 'year'} ago`
        }
        return  `${diff} ${diff > 1 ? 'months' : 'month'} ago`
      }
      return  `${diff} ${diff > 1 ? 'days' : 'day'} ago`
    }
    return  `${diff} ${diff > 1 ? 'hours' : 'hour'} ago`
  }

	return  `${diff} ${diff > 1 ? 'minutes' : 'minutes'} ago`
}
export const Entry = ({ mention, isFocused, id, onMouseUp, onMouseDown, onMouseEnter }) => {
    
    const entryRef = useRef(null);
    let className = "mention-text";
  
    if (isFocused) {
      className += " mention-focused";
    }
  
    useEffect(() => {
      if (isFocused) {
        if ("scrollIntoViewIfNeeded" in document.body) {
          entryRef.current.scrollIntoViewIfNeeded(false);
        } else {
          entryRef.current.scrollIntoView(false);
        }
      }
    }, [isFocused]);
  
    return (
      <div
        ref={entryRef}
        className="row p-2"
        role="option"
        aria-selected={isFocused ? "true" : "false"}
        id={id}
        onMouseUp={onMouseUp}
        onMouseEnter={onMouseEnter}
        onMouseDown={onMouseDown}
      >
          <div className="col-auto">
              <UserPhoto photo={mention.photo} name={mention.fullname} size={[30, 30, 30]} />
          </div>
          <div className="col">
            {mention.fullname}
        </div>
      </div>
    );
}


export function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          const popper = document.getElementsByClassName('popper')
          const flatpickr = document.getElementsByClassName('flatpickr-calendar')
        
          let bool = false
            
          if (!ref.current || ref.current.contains(event.target)) {  
            return;
          }

          if(popper){
            for(const p of popper){
                if(p.contains(event.target)) bool = true
            }
          }

          if(flatpickr){
            for (const f of flatpickr) {
                if(f.contains(event.target)) bool = true   
            }
          }

          if(bool) return

          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
}

export function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
}

export function debounceAsync(func, waitMs) {
    let timeout = null;
  
    return (...args) => {
      clearTimeout(timeout);
      return new Promise((resolve) => {
        timeout = setTimeout(() => resolve(func(...args)), waitMs);
      });
    };
}

export const drawStatus = (status) => {
    switch(status){
        case 1: return <span className='status-bubble status-1'>Open</span>
        case 2: return <span className='status-bubble status-2'>Draft</span>
        case 3: return <span className='status-bubble status-3'>Offer sent</span>
        default: return <span>Ops</span>
    }
}

export const statusBubble = (status) => {
    switch(status){

        //Rød og uncheck
        case 'not-accepted': return <span className='status-bubble bg-danger text-white'>Not accepted</span> 
        //Gul og check
        case 'fulfilled': return <span className='status-bubble bg-warning status-checked'>Fulfilled</span>
        case 'confirmed': return <span className='status-bubble bg-warning status-checked'>Confirmed</span>

        //Gul og uncheck
        case 'open': return <span className='status-bubble bg-warning'>Open</span>
        case 'opened': return <span className='status-bubble bg-warning'>Opened</span>
        case 'unopened': return <span className='status-bubble bg-warning'>Unopened</span>
        case 'no-invoice':    return <span className='status-bubble status-checked'>No invoice</span>
        case 'partially-paid':    return <span className='status-bubble status-checked'>Partially paid</span>

        //Grå og check
        case 'accepted':    return <span className='status-bubble status-checked'>Accepted</span>
        case 'closed':    return <span className='status-bubble status-checked'>Closed</span>
        case 'offer-sent':    return <span className='status-bubble status-checked'>Offer sent</span>
        case 'paid':    return <span className='status-bubble status-checked'>Paid</span>
        case 'fully-paid':    return <span className='status-bubble status-checked'>Fully paid</span>

        //Grå og uncheck
        case 'draft':       return <span className='status-bubble'>Draft</span>
        case 'for-review':  return <span className='status-bubble'>For review</span>
        case 'unfulfilled': return <span className='status-bubble'>Unfulfilled</span>
        case 'unpaid': return <span className='status-bubble'>Unpaid</span>

        //Grønn og check
        case 'ready': return <span className='status-bubble bg-success status-checked' style={{"--bs-bg-opacity": .7}}>Ready to go</span>

        default: return <span>{status}</span>
    }
}

export const getInitials = (fullname) => {
  if(!fullname) return 'XX'
	const split = fullname.split(" ")
	return split[0].substr(0, 1) + split[split.length - 1].substr(0,1)
}

export const drawProfileBubble = (user) => {
  const {APIURL} = useContext(AuthContext)
  if(!user) return
  return (
    <div className="profile-image">
      { user.photo ? <img src={`${APIURL}${user.photo}`} /> : getInitials(user.fullname) }
    </div>
  )
  return 
}

export const expenseBubble = (min, max) => {
	if(min === max) return ''
}

export const channelToCountry = (channel) => {
  switch(channel){
    case 'NO': return 'Norway'
    case 'SE': return 'Sweden'
    default: return 'Unknown'
  }
}

export const channelToLanguage = (channel) => {
  switch(channel){
    case 'NO': return 'Norwegian'
    case 'SE': return 'Swedish'
    default: return 'Swedish'
  }
}

export const channelCurrency = (channel) => {
  switch(channel){
    case 'NO': return 'NOK'
    case 'SE': return 'SEK'
    case 'DK': return 'DKK'
    default: return 'UNKWN'
  }
}

export function status({status, message}){
  switch(status){
    case 1:
      toast.success(message)
      break
    case 2:
      toast.warning(message)
      break
    case 3:
      toast.error(message)
      break
    default:
      toast.info(message)
  }
}

export const getOrdinal = (n) => {
  let ord = 'th';

  if (n % 10 == 1 && n % 100 != 11)
  {
    ord = 'st';
  }
  else if (n % 10 == 2 && n % 100 != 12)
  {
    ord = 'nd';
  }
  else if (n % 10 == 3 && n % 100 != 13)
  {
    ord = 'rd';
  }

  return ord;
}