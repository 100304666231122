import moment from 'moment'
import Table from '../components/Table'
import { useNavigate } from 'react-router'
import { channelCurrency, formatDate, status, statusBubble } from '../components/Functions'
import { useContext, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getPreviousMonths, getRandomBookings, getRandomEmployeeSales, getRandomOffers, getRandomOrders } from '../components/FakeApi'
import { AuthContext } from '../providers/AuthProvider'
import { CSVLink, CSVDownload } from "react-csv";

export default function Overview(){
    return (
        <div className="p-md-5 p-3 mb-5">
            <Orders />
            <div className="row mt-5">
                <div className="col-md-6">
                    <SalesMonth />
                </div>
                <div className="col-md-6 mb-5">
                    <SalesEmployee />
                </div>
            </div>
        </div>
    )
}



function Orders(){
    const navigate = useNavigate()
    const {apiCall } = useContext(AuthContext)
    
    const [data, setData] = useState(null)
    const [filteredData, setFilteredData] = useState(data)
    const [toggleFilters, setToggleFilter] = useState(false)
    const [filter, setFilter] = useState('all')
    const [channelFilter, setChannelFilter] = useState('all')
    const [search, setSearch] = useState('')
    const [range, setRange] = useState({
        filter: 'departure',
        from: moment().startOf('month').format('YYYY-MM-DD'),
        to: moment().endOf('month').format('YYYY-MM-DD'),
    })

    const exportRef = useRef()

    const searchRef = useRef()
    
    useEffect(() => {
        if(toggleFilters && searchRef && searchRef.current) searchRef.current.focus() 
    }, [toggleFilters])

    
    useEffect(() => {
        getOrders()
    }, [range])

    const getOrders = async () => {
        const d = await apiCall({
            action: 'getOrdersByRange',
            ...range
        })
        setData(d.data)
        console.log(d)
    }

    useEffect(() => {
        if(data) setFilteredData(data)
    }, [data])

    useEffect(() => {
        var temp

        temp = filterChannel(data)
        temp = filterExpenses(temp)
        
        if(search.length){
            var condition = search.toLowerCase()
            const searchFilter = temp   .filter(el => {
                let bool = false
                Object.keys(el).forEach((obj, key) => {
                    if(typeof el[obj] === 'string' && el[obj].toLowerCase().includes(condition)) bool = true
                })
                return bool
            })
            temp = searchFilter
        }

        setFilteredData(temp)
    }, [filter, search, channelFilter])

    const filterChannel = (data) => {
        if(channelFilter === 'all') return data 
        return data.filter((a) => a.channel === channelFilter)
    }

    const filterExpenses = (data) => {
        if(filter === 'all') return data
        if(data){
            return data.filter((d) => {
                const min = d.expenses.filter((e) => e.status === 'paid').length
                const max = d.expenses.length
                if(filter === 'noexpenses') return d.expenses.length === 0
                if(filter === 'unpaid') return min < max
            })
        }
        return data
        
    }

    const FilterButton = ({label, value}) => {
        return <div className="col-auto"><button className={`toggle-button ${filter === value ? 'active' : ''}`} onClick={() => setFilter(value)}>{label}</button></div>
    }

    const renderDepartureMonths = () => {
        const now = moment()
        const start = moment()
        const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        
        return arr.map(() => {
            start.add(1, 'month')
            return (
                <option value={JSON.stringify({ 
                    filter: 'departure',
                    from: start.startOf('month').format('YYYY-MM-DD'),
                    to: start.endOf('month').format('YYYY-MM-DD'),
                })}>Departures {start.format('MMMM')} {start.format('YYYY') !== now.format('YYYY') ? start.format('YYYY') : null}</option>
            )
        })
    }

    return (
        <div>
            <div className="row">
                <div className="col">
                    <h3 className='mb-4'>Orders</h3>
                </div>
                <div className="col-auto">
                    <button ref={exportRef} className='btn btn-sm btn-grey'>Export</button>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-auto">
                    <div className="small-select">
                        <select onChange={(e) => setRange(JSON.parse(e.target.value))} defaultValue={JSON.stringify({ filter: 'departure',
                                from: moment().startOf('month').format('YYYY-MM-DD'),
                                to: moment().endOf('month').format('YYYY-MM-DD'),
                            })}>
                            <option value={JSON.stringify({ filter: 'departure',
                                from: moment().subtract(1,'month').startOf('month').format('YYYY-MM-DD'),
                                to: moment().subtract(1,'month').endOf('month').format('YYYY-MM-DD'),
                            })}>Departures previous month</option>
                            <option value={JSON.stringify({ filter: 'departure',
                                from: moment().startOf('month').format('YYYY-MM-DD'),
                                to: moment().endOf('month').format('YYYY-MM-DD'),
                            })}>Departures this month</option>
                            {
                                renderDepartureMonths()
                            }
                            <option value={JSON.stringify({ filter: 'departure',
                                from: moment().add(1,'month').startOf('month').format('YYYY-MM-DD'),
                                to: moment().add(1,'month').endOf('month').format('YYYY-MM-DD'),
                            })}>Departures next month</option>
                            
                            <option value={JSON.stringify({
                                filter: 'departure',
                                from: moment().subtract(1,'year').startOf('year').format('YYYY-MM-DD'),
                                to: moment().subtract(1,'year').endOf('year').format('YYYY-MM-DD'),
                            })}>Departures last year</option>
                            <option value={JSON.stringify({
                                filter: 'departure',
                                from: moment().startOf('year').format('YYYY-MM-DD'),
                                to: moment().endOf('year').format('YYYY-MM-DD'),
                            })}>Departures this year</option>
                            <option value={JSON.stringify({
                                filter: 'departure',
                                from: moment().add(1, 'year').startOf('year').format('YYYY-MM-DD'),
                                to: moment().add(1, 'year').endOf('year').format('YYYY-MM-DD'),
                            })}>Departures next year</option>
                            <option value={JSON.stringify({
                                filter: 'createdon',
                                from: moment().startOf('year').format('YYYY-MM-DD'),
                                to: moment().endOf('year').format('YYYY-MM-DD'),
                            })}>Orders this year</option>
                            <option value={JSON.stringify({
                                filter: 'createdon',
                                from: moment().subtract(1,'year').startOf('year').format('YYYY-MM-DD'),
                                to: moment().subtract(1,'year').endOf('year').format('YYYY-MM-DD'),
                            })}>Orders last year</option>
                        </select>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="small-select">
                        <select onChange={(e) => setChannelFilter(e.target.value)}>
                            <option value="all">All channels</option>
                            <option value="SE">SE</option>
                            <option value="NO">NO</option>
                        </select>
                    </div>
                </div>
            </div>
            <Table
                exportRef={exportRef}
                gridTemplateColumns={["3fr 2fr 3fr 2fr 2fr 1fr", "3fr 2fr 3fr 2fr 2fr 1fr", "1fr 1fr 1fr"]}
                gridTemplateRows={["2fr", "2fr", "3fr"]}
                perPage={5}
                rows={filteredData}
                filters={
                    <div className='py-2 px-3'>
                        <div className="row">
                            <div className="col">
                                <div className="row gx-2">
                                    {
                                        [
                                        {label: 'All', value: 'all'},
                                        {label: 'Unpaid expenses', value: 'unpaid'},
                                        {label: 'No expenses', value: 'noexpenses'},
                                        ].map((item, key) => <FilterButton key={key} {...item} />)
                                    }
                                </div>
                            </div>
                            <div className="col-auto">
                                <button className="filter-button" onClick={() => setToggleFilter(!toggleFilters)}>
                                    <div className="row gx-1">
                                        <div className="col-auto"><FontAwesomeIcon icon={['far', 'magnifying-glass']} /></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        {toggleFilters ?
                        <div className='mt-2'>
                            <input ref={searchRef} type='text' className='form-control' placeholder='Search' onChange={(e) => setSearch(e.target.value)} value={search} />
                        </div>: null}
                    </div>
                }
                headers={[
                    { title: 'Booking', key: 'booking_no', sort: 'string'},
                    { title: 'Orderdate', key: 'createdon', sort: 'date'},
                    { title: 'Departure', key: 'departure', sort: 'date'},
                    { title: 'Customer', key: 'customer', sort: 'string', sortValue: (e) => e.customer?.fullname},
                    { title: 'Destination', key: 'destination', sort: 'string', sortValue: (e) => e.destination?.[0]},
                    { title: 'Seller', key: 'seller', sort: 'string', sortValue: (e) => e.seller?.fullname},
                    { title: 'Fulfilment', key: 'status', sort: 'string'},
                    { title: 'Expenses', key: 'expenses', sort: 'array'},
                    { title: 'Pax', key: 'persons', sort: 'array', total: true},
                    { title: 'Price', key: 'price', sort: 'number', total: true},
                    { title: 'Profit', key: 'profit', sort: 'number', total: true, totalCalculation: () => {
                        if(filteredData?.length){
                            const expenses = filteredData.reduce((total, obj) => {
                                return total + obj.expenses.reduce((t, o) => t + o.price, 0)
                            }, 0)
                            const totalPrice = filteredData.reduce((total, obj) => {
                                return total + obj.price
                            }, 0)

                            return (totalPrice - expenses).toLocaleString('nb-NO')
                        }
                        return 'Loading'
                        //const sum = filteredexpenses.reduce((total, obj) => total + obj.price, 0)
                    }, sortValue: (e) => {
                        const sum = e?.expenses.reduce((total, obj) => total + obj.price, 0)
                        return e?.price - sum
                    }},
                ]}
                total={['pax', 'price', 'profit']}
                onRowClick={({id}) => {
                    navigate(`/bookings/${id}`)
                }}
                onCtrlClick={({id}) => window.open(`${window.location.origin}/bookings/${id}`)}
                customSort={(rows) => {
                    if(rows){
                        const sorted = rows.sort((a, b) => {
                            
                            const a1 =  parseInt(moment(a.createdon).format('X'))
                            const b1 = parseInt(moment(b.createdon).format('X'))
                            
                            return a1 - b1
                        })

                        
                        return sorted
                    }
                }}
                columns={[
                    { 
                        grid: ["1/1", "auto", "1/1"],
                        display: ({booking_no}) => {
                            return <div>#{booking_no}</div>
                        }
                        
                    },
                    {
                        grid: ["1/2", "auto", "2/1"],
                        display: ({createdon}) => {
                            
                            return (
                                <div className="row gx-1">
                                    <div className="col-auto">
                                        <span className={"bold"}>{moment(createdon).format('DD.MM.YY')}</span>
                                    </div>
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["2/2", "auto", "3/1"],
                        display: ({departure}) => {
                            return (
                                <div className="row gx-1">
                                    <div className="col-auto">
                                        <span className={"bold"}>{moment(departure).format('DD.MM.YY')}</span>
                                    </div>
                                </div>
                            )
                        }
                    },
                    {
                        grid: ["1/3", "1/3", "1/2"],
                        display: ({customer}) => {
                            return (<div>{customer?.fullname}</div>)
                        }
                    },
                    {
                        grid: ["2/1", "2/1", "2/2"],
                        display: ({destination}) => {
                            return (<div>{destination.join(', ')}</div>)
                        }
                    },
                    {
                        grid: ["2/3", "2/3", "3/2"],
                        display: ({seller}) => {
                            return (<div>{seller?.fullname}</div>)
                        }
                    },
                    {
                        grid: ["1/4", "1/4", "1/3"],
                        display: ({status}) => {
                            return (<div>{statusBubble(status)}</div>)
                        }
                    },
                    {
                        grid: ["2/4", "2/4", "2/3"],
                        display: ({expenses}) => {
                            if(!expenses.length){
                                return <div className={`expense-bubble bg-warning`}>N/A</div>
                            }
                            const min = expenses.filter((e) => e.status === 'paid').length
                            const max = expenses.length
                            
                            return <div className={`expense-bubble ${min === max ? 'bg-success' : 'bg-warning'}`}>{min} / {max}</div>
                        }
                    },
                    {
                        grid: ["auto", "auto", "3/3"],
                        display: ({persons}) => {
                            return (<div>{persons ? persons.length : 0}</div>)
                        }
                    } ,
                    {
                        grid: ["1/5", "1/5", "4/1"],
                        display: ({ price, channel }) => {
                            return (<div>{price ? `${channelCurrency(channel)} ${new Intl.NumberFormat('nb-NO').format(price)}` : <i>Not set</i>}</div>)
                        }
                    },
                    {
                        grid: ["2/5", "2/5", "4/2"],
                        display: ({expenses, price, channel}) => {
                            const sum = expenses.reduce((total, obj) => total + obj.price, 0)
                            return (<div>{channelCurrency(channel)} {new Intl.NumberFormat('nb-NO').format(price - sum)}</div>)
                        }
                    }
                ]}
            />
        </div>
    )
}

function SalesMonth(){
    
    const {apiCall} = useContext(AuthContext)

    const [data, setData] = useState(null)
    const [year, setYear] = useState(moment().format('YYYY'))
    const [channelFilter, setChannelFilter] = useState('all')

    useEffect(() => {
        //Hente inn data
        getData()

    }, [])

    const getData = async () => {
        const d = await apiCall({action: 'getSalesPerMonth'})
        if(d.status !== 1) status(d)
        if(d.status === 1) setData(d.data)
    }

    return (
        <div>
            <h3 className='mb-4'>Sales per month</h3>
            <div className="row mb-4">
                <div className="col-auto">
                    <div className="small-select">
                        <select onChange={(e) => setYear(e.target.value)}>
                            <option value={moment().format('YYYY')}>This year</option>
                            <option value={moment().subtract(1, 'year').format('YYYY')}>Last year</option>
                        </select>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="small-select">
                        <select onChange={(e) => setChannelFilter(e.target.value)}>
                            <option value="all">All channels</option>
                            <option value="SE">SE</option>
                            <option value="NO">NO</option>
                        </select>
                    </div>
                </div>
            </div>
            <Table
                gridTemplateColumns={["1fr 1fr 1fr 1fr", "1fr 1fr 1fr 1fr", "repeat(3, 1fr)"]}
                gridTemplateRows={["1fr", "1fr", "1fr"]}
                perPage={12}
                rows={data}
                headers={[
                    { title: 'Month', key: 'monthNumber', sort: 'number'},
                    { title: 'Revenue', key: 'bookings', total: true, sort:'number', totalCalculation: () => {
                        if(data?.length){
                            return data.reduce((accumulator, object) => {
                                if(object.bookings && object.bookings[year]){
                                    return accumulator + object.bookings[year].reduce((a, o) => {
                                        return a + (channelFilter === 'all' || o.channel === channelFilter ? o.price : 0)
                                    }, 0)
                                }
                                return accumulator + 0
                            }, 0).toLocaleString('nb-NO')
                        }
                    },
                        sortValue: (e) => {
                            if(e.bookings && e.bookings[year]){
                                return e.bookings[year].reduce((accumulator, object) => {
                                    return accumulator + (channelFilter === 'all' || object.channel === channelFilter ? object.price : 0)
                                }, 0)
                            }else{
                                return 0
                            }
                        }
                    },
                    { title: 'Previous year', key: 'previousyear',  total: true, sort: 'number', totalCalculation: () => {
                        if(data?.length){
                            return data.reduce((accumulator, object) => {
                                if(object.bookings && object.bookings[year - 1]){
                                    return accumulator + object.bookings[year - 1].reduce((a, o) => {
                                        return a + (channelFilter === 'all' || o.channel === channelFilter ? o.price : 0)
                                    }, 0)
                                }
                                return accumulator + 0
                            }, 0).toLocaleString('nb-NO')
                        }
                    },
                        sortValue: (e) => {
                            if(e.bookings && e.bookings[year - 1]){
                                return e.bookings[year- 1].reduce((accumulator, object) => {
                                    return accumulator + (channelFilter === 'all' || object.channel === channelFilter ? object.price : 0)
                                }, 0)
                            }else{
                                return 0
                            }
                        }
                    },
                    { title: '%', key: 'percent', classes: 'justify-content-end'}
                ]}
                rowClass={({ monthNumber }) => {
                    const date = moment(`01-${monthNumber}-${year}`)
                    return date.isAfter(moment().format(), 'month') ? 'text-danger' : ''
                }}
                columns={[
                    { 
                        grid: ["auto", "auto", "auto"],
                        display: ({month}) => {
                            return <div>{month}</div>
                        }
                        
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({bookings}) => {
                            if(bookings && bookings[year]){
                                return bookings[year].reduce((accumulator, object) => {
                                    return accumulator + (channelFilter === 'all' || object.channel === channelFilter ? object.price : 0)
                                }, 0).toLocaleString('nb-NO')
                            }else{
                                return <div>N/A</div>
                            }
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({bookings}) => {                                    
                            if(bookings && bookings[year - 1]){
                                return bookings[year - 1].reduce((accumulator, object) => {
                                    return accumulator + (channelFilter === 'all' || object.channel === channelFilter ? object.price : 0)
                                }, 0).toLocaleString('nb-NO')
                            }else{
                                return <div>N/A</div>
                            }
                        }
                    },
                    {
                        grid: ["auto", "auto", ""],
                        display: ({ bookings }) => {
                            let thisYear = 0
                            let lastYear = 0
                            if(!bookings || !bookings[year]) return <div>N/A</div>
                            if(!bookings || !bookings[year - 1]) return <div>N/A</div>

                            
                            bookings[year].forEach((b) => {
                                if(channelFilter === 'all' || b.channel === channelFilter){
                                    thisYear += b.price
                                }
                            })

                            bookings[year - 1].forEach((b) => {
                                if(channelFilter === 'all' || b.channel === channelFilter){
                                    lastYear += b.price
                                }
                            })
                            
                            const difference = thisYear - lastYear;
                            const percentageDifference = difference === 0 ? 0 : (difference / Math.abs(lastYear)) * 100;
                            return (
                                percentageDifference === 0 ? <div></div> :
                                <div className='row justify-content-end gx-2'>
                                    <div className="col-auto">
                                        {Math.floor(percentageDifference)}%
                                    </div>
                                    <div className="col-auto">
                                        {percentageDifference < 0 ? <FontAwesomeIcon className='text-danger' icon={['far', 'arrow-down']} /> : <FontAwesomeIcon className='text-success' icon={['far', 'arrow-up']} />}
                                    </div>
                                     
                                </div>
                            )
                            
                        }
                    }
                ]}
            />
        </div>
    )
}

function SalesEmployee(){
    const {apiCall} = useContext(AuthContext)

    const [data, setData] = useState(null)
    const [year, setYear] = useState(moment().format('YYYY'))
    const [channelFilter, setChannelFilter] = useState('all')

    useEffect(() => {
        //Hente inn data
        getData()

    }, [])

    const getData = async () => {
        const d = await apiCall({action: 'getSalesPerSeller'})
        if(d.status !== 1) status(d)
        if(d.status === 1) setData(d.data)
    }

    return (
        <div>
            <h3 className='mb-4'>Sales per employee</h3>
            <div className="row mb-4">
                <div className="col-auto">
                    <div className="small-select">
                        <select onChange={(e) => setYear(e.target.value)}>
                            <option value={moment().format('YYYY')}>This year</option>
                            <option value={moment().subtract(1, 'year').format('YYYY')}>Last year</option>
                        </select>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="small-select">
                        <select onChange={(e) => setChannelFilter(e.target.value)}>
                            <option value="all">All channels</option>
                            <option value="SE">SE</option>
                            <option value="NO">NO</option>
                        </select>
                    </div>
                </div>
            </div>
            <Table
                gridTemplateColumns={["2fr 1fr 1fr 1fr", "2fr 1fr 1fr 1fr", "repeat(3, 1fr)"]}
                gridTemplateRows={["1fr", "1fr", "1fr"]}
                perPage={12}
                rows={data}
                defaultSort={'bookings'}
                headers={[
                    { title: 'Employee', key: 'name', sort: 'string'},
                    { title: 'Revenue', key: 'bookings', sort: 'number', sortValue: (e) => {
                        
                        if(e.bookings[year]){
                            let sum = 0
                                e.bookings[year].forEach((b) => {
                                    if(channelFilter === 'all' || b.channel === channelFilter){
                                        sum += b.price
                                    }
                                })

                                return sum
                        }else{ return 0}
                    }},
                    { title: 'Previous year', key: 'prev', sort: 'number', sortValue: (e) => {
                        
                        if(e.bookings[year -1]){
                            let sum = 0
                                e.bookings[year-1].forEach((b) => {
                                    if(channelFilter === 'all' || b.channel === channelFilter){
                                        sum += b.price
                                    }
                                })

                                return sum
                        }else{ return 0}
                    }},
                    { title: '%', key: 'percent', classes: 'justify-content-end'}
                ]}
                columns={[
                    { 
                        grid: ["auto", "auto", "auto"],
                        display: ({name}) => {
                            return <div>{name}</div>
                        }
                        
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({bookings}) => {
                            if(bookings && bookings[year]){
                                let sum = 0
                                bookings[year].forEach((b) => {
                                    if(channelFilter === 'all' || b.channel === channelFilter){
                                        sum += b.price
                                    }
                                })

                                return sum.toLocaleString('nb-NO')
                            }else{
                                return <div>N/A</div>
                            }
                        }
                    },
                    {
                        grid: ["auto", "auto", "auto"],
                        display: ({bookings}) => {                                    
                            if(bookings && bookings[year - 1]){
                                let sum = 0
                                bookings[year - 1].forEach((b) => {
                                    if(channelFilter === 'all' || b.channel === channelFilter){
                                        sum += b.price
                                    }
                                })

                                return sum.toLocaleString('nb-NO')
                            }else{
                                return <div>N/A</div>
                            }
                        }
                    },
                    {
                        grid: ["auto", "auto", ""],
                        display: ({ bookings }) => {
                            let thisYear = 0
                            let lastYear = 0
                            if(!bookings || !bookings[year]) return <div>N/A</div>
                            if(!bookings || !bookings[year - 1]) return <div>N/A</div>

                            
                            bookings[year].forEach((b) => {
                                if(channelFilter === 'all' || b.channel === channelFilter){
                                    thisYear += b.price
                                }
                            })

                            bookings[year - 1].forEach((b) => {
                                if(channelFilter === 'all' || b.channel === channelFilter){
                                    lastYear += b.price
                                }
                            })
                            
                            const difference = thisYear - lastYear;
                            const percentageDifference = difference === 0 ? 0 : (difference / Math.abs(lastYear)) * 100;
                            return (
                                percentageDifference === 0 ? <div></div> :
                                <div className='row justify-content-end gx-2'>
                                    <div className="col-auto">
                                        {Math.floor(percentageDifference)}%
                                    </div>
                                    <div className="col-auto">
                                        {percentageDifference < 0 ? <FontAwesomeIcon className='text-danger' icon={['far', 'arrow-down']} /> : <FontAwesomeIcon className='text-success' icon={['far', 'arrow-up']} />}
                                    </div>
                                     
                                </div>
                            )
                            
                        }
                    }
                ]}
            />
        </div>
    )
}